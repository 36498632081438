import { Fragment, useEffect, useMemo, useState } from 'react';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Skeleton,
    TableCell,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { useTranslation } from '@/hooks/translations';
import { getCurrencyWithAmount } from '@/common/utility';
import QsrOrdersService from '@/services/qsrOrders';
import { IOrderItem, OrderStatusEnum, IQsrOrder } from '@/views/QsrOrders/types';
import { MenuCategoryResponse, MenuProductResponse } from '@/services/menu/types';
import { getAllCategoriesFromOrderData, getAllProductsFromOrderData } from '@/services/utils/qsr';
import { IRestaurant, OrderPaymentMethodEnum } from '@/views/OrdersTableView/types';
import QsrUserInfo from '@/components/QsrUserInfo';
import QsrPaymentStatus from '@/components/QsrPaymentStatus';
import { NameTrans } from '@/components/Trans';
import { useRestaurantContext } from '@/contexts/restaurant';
import { Cell, PrimaryButton, Section, TableContent } from './common';

interface IQsrRowsProps {
    items: IOrderItem[];
    productMap: { [key: string]: MenuProductResponse };
    categoryMap: { [key: string]: MenuCategoryResponse };
    currencyCode: string;
    currencySymbol: string;
    indent: number;
    lang: string;
    qty?: number;
    loading: boolean;
    rowSx?: any;
    version?: 'v2';
}

export function QsrRows({
    items,
    productMap,
    categoryMap,
    currencyCode,
    currencySymbol,
    indent,
    lang,
    qty,
    loading,
    rowSx,
    version,
}: IQsrRowsProps) {
    const { restaurant } = useRestaurantContext();
    const { t } = useTranslation('common');
    const priceRoundingMode = restaurant?.order_config?.priceRoundingMode;
    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {items?.map((item, index) => {
                const sx =
                    indent === 0
                        ? {
                              fontSize: '15px',
                          }
                        : {
                              fontSize: '12px',
                              paddingTop: '2px',
                              paddingBottom: '2px',
                          };
                const rSx =
                    rowSx ||
                    (!indent && index % 2 === 1
                        ? {
                              backgroundColor: 'rgba(147,147,147,0.1)',
                          }
                        : undefined);
                return (
                    <Fragment key={`${item.id}_${index}`}>
                        <TableRow sx={rSx}>
                            <Cell sx={sx}>
                                {item.mgId && (
                                    <Box sx={{ paddingLeft: `${indent * 8}px`, fontWeight: 'bold' }}>
                                        {loading ? (
                                            <Skeleton height="18px" width="64px" />
                                        ) : (
                                            <NameTrans lang={lang} obj={categoryMap[item.mgId]} def={item.mgId} />
                                        )}
                                    </Box>
                                )}
                                <Box sx={{ paddingLeft: `${indent * 8}px` }}>
                                    {loading ? (
                                        <Skeleton height="18px" width="64px" />
                                    ) : (
                                        <NameTrans lang={lang} obj={productMap[item.id]} def={item.id} />
                                    )}
                                </Box>
                            </Cell>
                            <Cell sx={sx}>{item.quantity * (qty || 1)}</Cell>
                            <Cell sx={sx} align="right">
                                {getCurrencyWithAmount(
                                    item.price,
                                    currencySymbol,
                                    currencyCode,
                                    true,
                                    priceRoundingMode,
                                )}
                            </Cell>
                        </TableRow>
                        {item.additives && (
                            <QsrRows
                                items={item.additives}
                                productMap={productMap}
                                categoryMap={categoryMap}
                                currencyCode={currencyCode}
                                currencySymbol={currencySymbol}
                                indent={indent + 1}
                                lang={lang}
                                qty={item.quantity}
                                loading={loading}
                                rowSx={rSx}
                                version={version}
                            />
                        )}
                        {version === 'v2' && item.note && (
                            <TableRow sx={rSx}>
                                <Cell sx={sx} colSpan={3}>
                                    <b>{t('Note:')}</b>&nbsp;
                                    {item.note}
                                </Cell>
                            </TableRow>
                        )}
                    </Fragment>
                );
            })}
        </>
    );
}

interface IQsrActionProps {
    data: IQsrOrder | null;
    loading: boolean;
    restaurant: IRestaurant | null;
    onAction: (status: OrderStatusEnum, ticket: boolean) => void;
}

interface IAction {
    id: string;
    color: string;
    bgColor?: string;
    secondaryBgColor?: string;
    title: string;
    status: OrderStatusEnum;
}

export function QsrAction({ data, loading, restaurant, onAction }: IQsrActionProps) {
    const { t } = useTranslation('common');

    const items = useMemo<IAction[]>(() => {
        if (!data || !restaurant) {
            return [];
        }

        const getStatus = (): IAction[] => {
            switch (data?.orderStatus?.name) {
                default:
                case OrderStatusEnum.Pending:
                    return [
                        {
                            id: 'accept',
                            title: t('Accept'),
                            color: '#fff',
                            bgColor: '#1C8659',
                            secondaryBgColor: '#09693d',
                            status: OrderStatusEnum.Approved,
                        },
                        {
                            id: 'accept-ticket',
                            title: t('Accept with Ticket'),
                            color: '#fff',
                            bgColor: '#1C8659',
                            secondaryBgColor: '#09693d',
                            status: OrderStatusEnum.Approved,
                        },
                        {
                            id: 'decline',
                            title: t('Decline'),
                            color: '#fff',
                            bgColor: '#FF0000',
                            secondaryBgColor: '#ce0000',
                            status: OrderStatusEnum.Declined,
                        },
                    ];
                case OrderStatusEnum.Approved:
                    return [
                        {
                            id: 'close',
                            title: t('Done'),
                            color: '#fff',
                            bgColor: '#1C8659',
                            secondaryBgColor: '#09693d',
                            status: OrderStatusEnum.Closed,
                        },
                        {
                            id: 'close-ticket',
                            title: t('Done with Ticket'),
                            color: '#fff',
                            bgColor: '#1C8659',
                            secondaryBgColor: '#09693d',
                            status: OrderStatusEnum.Closed,
                        },
                        {
                            id: 'decline',
                            title: t('Decline'),
                            color: '#fff',
                            bgColor: '#FF0000',
                            secondaryBgColor: '#ce0000',
                            status: OrderStatusEnum.Declined,
                        },
                    ];
                case OrderStatusEnum.Declined:
                    return [
                        {
                            id: 'accept',
                            title: t('Accept'),
                            color: '#fff',
                            bgColor: '#1C8659',
                            secondaryBgColor: '#09693d',
                            status: OrderStatusEnum.Approved,
                        },
                    ];
                case OrderStatusEnum.Active:
                case OrderStatusEnum.Closed:
                case OrderStatusEnum.Scheduled:
                    return [];
            }
        };
        if (!restaurant?.order_config?.vendorQsrCardViewActions?.length) {
            return getStatus();
        }
        return getStatus().filter((o) => restaurant?.order_config?.vendorQsrCardViewActions?.includes(o.status));
    }, [data, restaurant]);

    return (
        <>
            <Box sx={{ flex: '1 1' }} />
            {items.map((item) => {
                return (
                    <PrimaryButton
                        id={`${item.id}-btn`}
                        styles={{
                            color: item.color,
                            ...(item.bgColor ? { backgroundColor: item.bgColor } : {}),
                            ...(item.secondaryBgColor
                                ? {
                                      '&:hover': {
                                          backgroundColor: item.secondaryBgColor,
                                      },
                                  }
                                : {}),
                        }}
                        onClick={() => onAction(item.status, item.id.includes('-ticket'))}
                        text={item.title}
                        disabled={loading}
                    />
                );
            })}
        </>
    );
}

interface IProps {
    open: boolean;
    onClose: () => void;
    onDone: (status: OrderStatusEnum, ticketId: string, refId: string) => void;
    data: IQsrOrder | null;
    currencyCode: string;
    currencySymbol: string;
    restaurant: IRestaurant | null;
    lang?: string;
}

export default function QsrOrdersDetails({
    open,
    onClose,
    onDone,
    data,
    restaurant,
    currencyCode,
    currencySymbol,
    lang,
}: IProps) {
    const { t } = useTranslation('common');
    const qsrOrdersService = QsrOrdersService.getInstance();
    const [productMap, setProductMap] = useState<{ [key: string]: MenuProductResponse }>({});
    const [productLoading, setProductLoading] = useState(false);
    const [categoryMap, setCategoryMap] = useState<{ [key: string]: MenuCategoryResponse }>({});
    const [categoryLoading, setCategoryLoading] = useState(false);
    const [actionLoading, setActionLoading] = useState(false);
    const [ticketOpen, setTicketOpen] = useState(false);
    const [ticketId, setTicketId] = useState('');
    const [ticketStatus, setTicketStatus] = useState<OrderStatusEnum>(OrderStatusEnum.Pending);
    const id = data?.id || '';
    const priceRoundingMode = restaurant?.order_config?.priceRoundingMode;

    const setOrderStatus = (status: OrderStatusEnum) => {
        setActionLoading(true);
        qsrOrdersService
            .setQsrOrderStatus({ restaurantId: restaurant?.id || '', orderId: id, status, ticketId })
            .then(() => {
                onDone(status, data?.ticketId || ticketId || null, data?.refId || '');
            })
            .catch((err) => console.log('err', err))
            .finally(() => {
                setTimeout(() => {
                    onClose();
                    setTicketId('');
                    setTicketOpen(false);
                }, 100);
                setActionLoading(false);
            });
    };

    const orderStatusHandler = (status: OrderStatusEnum, ticket: boolean) => {
        if (ticket) {
            setTicketOpen(true);
            setTicketStatus(status);
        } else {
            setOrderStatus(status);
        }
    };

    useEffect(() => {
        if (!data) {
            return;
        }

        setProductLoading(true);
        qsrOrdersService
            .getProductMap(restaurant?.id || '', { ids: getAllProductsFromOrderData(data.orderData) })
            .then((res) => {
                setProductMap(res);
            })
            .finally(() => {
                setProductLoading(false);
            });
    }, [data]);

    useEffect(() => {
        if (!data) {
            return;
        }

        setCategoryLoading(true);
        qsrOrdersService
            .getCategoryMap(restaurant?.id || '', { ids: getAllCategoriesFromOrderData(data.orderData) })
            .then((res) => {
                setCategoryMap(res);
            })
            .finally(() => {
                setCategoryLoading(false);
            });
    }, [data]);

    return (
        <>
            <Dialog
                onClose={() => {
                    setTicketOpen(false);
                }}
                open={ticketOpen}
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle sx={{ fontSize: '30px' }}>{t('Ticket')}</DialogTitle>
                <DialogContent sx={{ p: '12px !important' }}>
                    <TextField
                        label={t('Ticket')}
                        fullWidth
                        value={ticketId}
                        onChange={(e) => {
                            setTicketId(e.target.value);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <PrimaryButton
                        id="ticket-btn"
                        color="primary"
                        text={t('Apply')}
                        fullWidth
                        disabled={actionLoading}
                        onClick={() => {
                            setOrderStatus(ticketStatus);
                        }}
                    />
                </DialogActions>
            </Dialog>
            <Dialog onClose={onClose} open={open} fullWidth maxWidth="md">
                <DialogTitle sx={{ fontSize: '30px' }}>{t('Details')}</DialogTitle>
                <DialogContent>
                    <Section sx={{ marginTop: '0.5rem' }}>
                        <TableContent
                            headCells={
                                <>
                                    <TableCell>{t('Item')}</TableCell>
                                    <TableCell>{t('QTY')}</TableCell>
                                    <TableCell align="right">{t('Price')}</TableCell>
                                </>
                            }
                        >
                            <QsrRows
                                items={data?.orderData.items || []}
                                currencyCode={currencyCode}
                                currencySymbol={currencySymbol}
                                indent={0}
                                productMap={productMap}
                                categoryMap={categoryMap}
                                lang={restaurant?.order_config?.printerLang || 'en'}
                                loading={productLoading && categoryLoading}
                                version={data?.orderData?.version}
                            />
                        </TableContent>
                        <Typography variant="body1" sx={{ marginTop: '1rem' }} align="right">
                            {t(`Total Price {{total}}`, {
                                total: getCurrencyWithAmount(
                                    data?.total,
                                    currencySymbol,
                                    currencyCode,
                                    true,
                                    priceRoundingMode,
                                ),
                            })}
                        </Typography>
                        {data &&
                            [OrderPaymentMethodEnum.PayLater].includes(
                                restaurant?.order_config?.paymentMethod || OrderPaymentMethodEnum.Default,
                            ) && <QsrPaymentStatus order={data} />}
                        {data?.customerComment && (
                            <Typography
                                variant="body2"
                                sx={{
                                    marginTop: '1rem',
                                    padding: '8px 0 0',
                                    borderTop: '1px solid #bbb',
                                    whiteSpace: 'pre-wrap',
                                }}
                                align="left"
                            >
                                <b>{t('Customer Comment: ')}</b>
                                {data?.customerComment}
                            </Typography>
                        )}
                    </Section>
                    {data && <QsrUserInfo order={data} restaurant={restaurant} lang={lang} normalMargin />}
                </DialogContent>
                <DialogActions
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: ' 1rem 1.6rem 1.6rem 1.6rem',
                        backgroundColor: '#F8F8F8',
                    }}
                >
                    <PrimaryButton
                        id="close-btn"
                        styles={{ backgroundColor: '#C4C4C4' }}
                        color="grey"
                        onClick={onClose}
                        text={t('Close')}
                    />
                    <QsrAction
                        data={data}
                        loading={actionLoading}
                        restaurant={restaurant}
                        onAction={orderStatusHandler}
                    />
                </DialogActions>
            </Dialog>
        </>
    );
}
