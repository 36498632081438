import { MenuCategoryResponse, MenuDetailedResponse, MenuResponse } from '@/services/menu/types';
import { createContext, ReactNode, useState, useContext, useMemo } from 'react';

export interface DigitalMenuState {
    menus: MenuResponse[];
    menuDetails: MenuDetailedResponse | null;
    refreshFlag: boolean;
    currentCategory: MenuCategoryResponse | null;
    currentBundleId: string | null;
    allMenusSelected: boolean;
}
interface IDigitalMenuContext {
    digitalMenuState: any;
    setDigitalMenuState: any;
}

type Props = {
    children?: ReactNode;
};

const DigitalMenuContext = createContext<IDigitalMenuContext>({
    digitalMenuState: null,
    setDigitalMenuState: null,
});

const initialState: DigitalMenuState = {
    menus: [],
    menuDetails: null,
    refreshFlag: false,
    currentCategory: null,
    currentBundleId: null,
    allMenusSelected: false,
};

export function DigitalMenuContextProvider({ children }: Props) {
    const [digitalMenuState, setDigitalMenuState] = useState<DigitalMenuState>(initialState);
    const digitalMenuProviderValue = useMemo(
        () => ({ digitalMenuState, setDigitalMenuState }),
        [digitalMenuState, setDigitalMenuState],
    );
    return <DigitalMenuContext.Provider value={digitalMenuProviderValue}> {children} </DigitalMenuContext.Provider>;
}

export const useDigitalMenuContext = () => useContext(DigitalMenuContext);
