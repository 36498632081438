import { useState, useMemo, useEffect, FC } from 'react';
import { useRouter } from 'next/router';
import { useLanguageQuery } from 'next-export-i18n';
import { List, ListSubheader, Grid, Box, Drawer, Button, DrawerProps, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import EventBus from '@/config/event-handler';
import { vendorPaymentEvent, resetNotificationBadgeCount } from '@/contexts/event.const';
import { Percent, RestaurantMenuRounded, ReviewsOutlined, LocalOffer, QrCode2 } from '@mui/icons-material';

import { grays } from '@/theme';
import { useTranslation } from '@/hooks/translations';
import { useRestaurantContext } from '@/contexts/restaurant';
import { useUserContext } from '@/contexts/user';
import { setTimezone } from '@/common/utility';
import { hasQlubPosSection } from '@/hooks/useSiderbarRoutes';
import { IPaymentNotificationEvent } from '@/views/Payments';

import { queries, routes } from '@/constants/routes';
import { restrictRoles } from '@/constants/roleRestrictions';
import { RestaurantOrderMode } from '@/views/OrdersTableView/types';
import {
    AuthIcon,
    DashboardIcon,
    LeftChevron,
    NotificationIcon,
    OrdersIcon,
    PaymentsIcon,
    ProfileIcon,
    QrCodeIcon,
    QsrOrdersIcon,
    RestaurantIcon,
    RightChevron,
    ScheduledReportsIcon,
    TableViewIcon,
    PaymentLinksIcon,
} from '@/icons/svgIcons';
import { QlubLogo } from '../icon/logo';
import styles from './style.module.scss';
import { MenuItem } from './MenuItem';

const BADGE_RESET_DELAY_DURATION = 5000;

const SidebarContainer = styled(Grid)({
    backgroundColor: grays.main,
    position: 'relative',
    color: '#666666',
    height: '100vh',
});

interface IDashboardSidebarProps {
    onClose: (e?: any) => void;
    open: boolean;
    compact: boolean;
    lgUp: boolean;
    setCompact: (compact: boolean) => void;
    width: number;
    isTablet: boolean;
    isMobile: boolean;
}

const Sidebar: FC<IDashboardSidebarProps> = (props: IDashboardSidebarProps) => {
    const { t } = useTranslation('common');
    const [notificationBadge, setNotificationBadge] = useState(0);
    const { restaurant } = useRestaurantContext();
    const { user } = useUserContext();

    const [query] = useLanguageQuery();
    const lang = query ? query.lang : 'en';
    const { compact, isTablet, lgUp, onClose, open, setCompact, width, isMobile } = props;

    const router = useRouter();

    const getRoutes = () => ({
        general: [
            {
                show: true,
                text: t('Dashboard'),
                link: routes.HOME,
                Icon: DashboardIcon,
                restrictRoles: restrictRoles.HOME,
            },
            {
                show: true,
                text: t('Orders'),
                link: routes.ORDERS,
                Icon: OrdersIcon,
                restrictRoles: restrictRoles.ORDERS_AND_TRANSACTIONS,
            },
            {
                show: true,
                text: t('Payments'),
                link: routes.PAYMENTS,
                Icon: PaymentsIcon,
                restrictRoles: [...restrictRoles.PAYMENTS, ...restrictRoles.REFUND],
            },
            {
                show: true,
                text: t('Table View'),
                link: routes.TABLE_VIEW,
                Icon: TableViewIcon,
                restrictRoles: restrictRoles.NOTIFICATION,
            },
            ...(restaurant?.config?.vendor_enableReview
                ? [
                      {
                          show: true,
                          text: t('Reviews'),
                          link: routes.REVIEWS,
                          Icon: ReviewsOutlined,
                          restrictRoles: restrictRoles.REVIEWS,
                      },
                  ]
                : []),
            ...(user.posVendor === 'vpos'
                ? [
                      {
                          show: true,
                          text: t('VPOS'),
                          link: routes.VPOS,
                          Icon: RestaurantIcon,
                          restrictRoles: restrictRoles.VPOS,
                      },
                  ]
                : []),
            ...(hasQlubPosSection(restaurant)
                ? [
                      {
                          show: true,
                          text: t('QSR Orders'),
                          link: routes.QSR_ORDERS,
                          Icon: QsrOrdersIcon,
                          restrictRoles: restrictRoles.ORDERS_AND_TRANSACTIONS,
                      },
                  ]
                : []),
            ...(restaurant?.config?.enable_payment_link
                ? [
                      {
                          show: true,
                          text: t('Payment Links'),
                          link: routes.PAYMENT_LINKS,
                          Icon: PaymentLinksIcon,
                          restrictRoles: restrictRoles.NOTIFICATION,
                      },
                  ]
                : []),
        ],
        businessSettings: [
            {
                text: t('Restaurant Details'),
                link: routes.RESTAURANT_DETAILS,
                Icon: RestaurantIcon,
                restrictRoles: restrictRoles.RESTAURANT_DETAILS,
            },
            ...([RestaurantOrderMode.QSR, RestaurantOrderMode.DigitalMenu, RestaurantOrderMode.Mixed].includes(
                restaurant?.order_mode || RestaurantOrderMode.Default,
            )
                ? [
                      {
                          text: t('Menus'),
                          link: routes.DIGITAL_MENU,
                          Icon: RestaurantMenuRounded,
                          restrictRoles: restrictRoles.MENUS,
                      },
                      ...(restaurant?.config?.qsrTaxManagement
                          ? [
                                {
                                    text: t('Tax / Fees'),
                                    link: routes.TAX,
                                    Icon: Percent,
                                    restrictRoles: restrictRoles.MENUS,
                                },
                            ]
                          : []),
                      {
                          text: t('Tags'),
                          link: routes.TAG,
                          Icon: LocalOffer,
                          restrictRoles: restrictRoles.MENUS,
                      },
                      // {
                      //     text: t('Inventory'),
                      //     link: routes.DIGITAL_MENU,
                      //     Icon: RestaurantMenuRounded,
                      //     restrictRoles: restrictRoles.MENUS,
                      // },
                  ]
                : []),
            ...(user.posVendor === 'foodics'
                ? [
                      {
                          text: t('Integrate Foodics'),
                          link: routes.FOODICS,
                          Icon: RestaurantIcon,
                          restrictRoles: restrictRoles.FOODICS,
                      },
                  ]
                : []),
            ...(user.posVendor === 'ls-k-series'
                ? [
                      {
                          text: t('Integrate LS-K-Series'),
                          link: routes.LS_K_SERIES,
                          Icon: RestaurantIcon,
                          restrictRoles: restrictRoles.LS_K_SERIES,
                      },
                  ]
                : []),
            ...(user.posVendor === 'ls-o-series'
                ? [
                      {
                          text: t('Integrate LS-O-Series'),
                          link: routes.LS_O_SERIES,
                          Icon: RestaurantIcon,
                          restrictRoles: restrictRoles.LS_O_SERIES,
                      },
                  ]
                : []),
            {
                text: t('QR Codes'),
                link: routes.QR_CODE,
                Icon: QrCodeIcon,
                restrictRoles: restrictRoles.QR_CODE,
            },
            ...((restaurant?.config?.enableLinkit || restaurant?.restaurant_country?.config?.enableLinkit) === 'show'
                ? [
                      {
                          text: t('Linkit'),
                          link: routes.LINKIT,
                          Icon: QrCode2,
                          restrictRoles: restrictRoles.LINKIT,
                      },
                  ]
                : []),
        ],
        profileSettings: [
            {
                text: t('Profile Settings'),
                link: queries.PROFILE_SETTINGS,
                Icon: ProfileIcon,
                restrictRoles: restrictRoles.PROFILE_SETTINGS,
            },
            {
                text: t('Password & Authentication'),
                link: queries.PASSWORD_AND_AUTHENTICATION,
                Icon: AuthIcon,
                restrictRoles: restrictRoles.PROFILE_SETTINGS,
            },
            {
                text: t('Notification'),
                link: queries.NOTIFICATION_SETTINGS,
                Icon: NotificationIcon,
                restrictRoles: restrictRoles.PROFILE_SETTINGS,
            },
            {
                text: t('Scheduled Reports'),
                link: queries.SCHEDULED_REPORTS,
                Icon: ScheduledReportsIcon,
                restrictRoles: restrictRoles.SCHEDULED_REPORTS,
            },
        ],
    });

    const menuItems = useMemo(() => getRoutes(), [lang, user, restaurant]);

    useEffect(
        () => {
            if (!router.isReady) {
                return;
            }

            if (open) {
                onClose?.();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [router.asPath],
    );

    useEffect(() => {
        setTimezone(restaurant?.timezoneName || '');
    }, [restaurant]);

    useEffect(() => {
        EventBus.on(vendorPaymentEvent, ({ detail: msgList }: IPaymentNotificationEvent) => {
            setNotificationBadge((count) => count + msgList.length);
        });
        EventBus.on(resetNotificationBadgeCount, () => {
            setTimeout(() => {
                setNotificationBadge(0);
            }, BADGE_RESET_DELAY_DURATION);
        });

        return () => {
            // SocketConnector.getInstance().disconnect();
            EventBus.remove(vendorPaymentEvent);
        };
    }, []);

    const drawerCommonProps: DrawerProps = {
        anchor: 'left',
        PaperProps: {
            sx: {
                backgroundColor: 'neutral.900',
                color: '#FFFFFF',
                width: isTablet ? 80 : width,
            },
        },
    };

    const drawerProps: DrawerProps = {
        open: true,
        variant: 'permanent',
        className: styles.customSidebar,
    };

    const compactDrawerProps: DrawerProps = {
        onClose,
        open,
        sx: { zIndex: (theme: any) => theme.zIndex.appBar + 100 },
        variant: 'temporary',
    };

    return (
        <Drawer {...drawerCommonProps} {...(lgUp || isTablet ? drawerProps : compactDrawerProps)}>
            {!isTablet && (
                <Box className={styles.sidebarHeader}>
                    {!isMobile && (
                        <Button
                            id="sidebar-shrink-btn"
                            className={styles.sidebarShrinkButton}
                            startIcon={compact ? <RightChevron /> : <LeftChevron />}
                            variant="contained"
                            onClick={() => setCompact(!compact)}
                        />
                    )}
                </Box>
            )}
            <Box
                sx={{
                    width: '100%',
                    marginTop: {
                        xs: '1rem',
                        sm: '1rem',
                    },
                    backgroundColor: '#F8F9FA',
                }}
            >
                {isMobile ? <QlubLogo compact={!compact} width="100%" /> : <QlubLogo compact={compact} width="100%" />}
                <Divider sx={{ marginTop: '0.50rem', marginBottom: '1rem', backgroundColor: '#E5E7EB' }} />
            </Box>
            <SidebarContainer
                item
                container
                direction="row"
                alignContent="flex-start"
                sx={{
                    overflowX: 'hidden',
                    backgroundColor: '#F8F9FA',
                }}
            >
                <Box
                    sx={{
                        width: '100%',

                        paddingX: {
                            xs: '11.5px',
                        },
                    }}
                >
                    <List disablePadding>
                        {menuItems.general
                            .filter((page: any) => page.restrictRoles.includes(user?.userData?.role || '') && page.show)
                            .map((item, key) => (
                                <MenuItem
                                    key={key}
                                    link={item.link}
                                    item={item}
                                    notificationBadge={notificationBadge}
                                    compact={compact}
                                    isMobile={isMobile}
                                />
                            ))}
                    </List>
                    <List
                        disablePadding
                        subheader={
                            !!menuItems.businessSettings.filter((page) =>
                                page.restrictRoles.includes(user?.userData?.role),
                            ).length &&
                            (!compact ? (
                                <>
                                    <Divider sx={{ marginY: '4px', backgroundColor: '#E5E7EB' }} />
                                    <ListSubheader sx={{ backgroundColor: grays.gray, whiteSpace: 'nowrap' }}>
                                        {t('Business Settings')}
                                    </ListSubheader>
                                </>
                            ) : (
                                <Divider sx={{ marginY: '28px' }} />
                            ))
                        }
                    >
                        {menuItems.businessSettings
                            .filter((page) => {
                                return page.restrictRoles.includes(user?.userData?.role);
                            })
                            .map((item, key) => (
                                <MenuItem
                                    key={key}
                                    link={item.link}
                                    item={item}
                                    compact={compact}
                                    isMobile={isMobile}
                                />
                            ))}
                    </List>
                    <List
                        disablePadding
                        subheader={
                            !!menuItems.profileSettings.filter((page) =>
                                page.restrictRoles.includes(user?.userData?.role),
                            ).length &&
                            (!compact ? (
                                <>
                                    <Divider sx={{ marginY: '4px', backgroundColor: '#E5E7EB' }} />
                                    <ListSubheader sx={{ backgroundColor: grays.gray, whiteSpace: 'nowrap' }}>
                                        {t('Profile Settings')}
                                    </ListSubheader>
                                </>
                            ) : (
                                <Divider sx={{ marginY: '28px' }} />
                            ))
                        }
                    >
                        {menuItems.profileSettings
                            .filter((page) => {
                                return page.restrictRoles.includes(user?.userData?.role);
                            })
                            .map((item, key) => (
                                <MenuItem
                                    key={key}
                                    link={routes.SETTINGS}
                                    item={item}
                                    query={'page'}
                                    compact={compact}
                                    isMobile={isMobile}
                                />
                            ))}
                    </List>
                </Box>
            </SidebarContainer>
        </Drawer>
    );
};

export default Sidebar;
